<template>
  <div v-if="definition.type === 'select'">
    <v-select v-if="searchColumnItems != null"
              hide-details
              :filled="filled"
              :solo="filled !== true"
              :value="value"
              @change="inputValue"
              dense
              clearable
              :items="searchColumnItems"
              :prefix="definition.name + ' ' + (definition.prefix || '')"
              placeholder="Selecione"
              :hint="definition.hint"
              item-text="valor"
              item-value="key"
    ></v-select>
    <v-select disabled loading
              :filled="filled"
              dense
              hide-details
              :hint="definition.hint"
              :prefix="definition.name + ' ' + (definition.prefix || '')"
              placeholder="Selecione"
              :items="[]"
              :solo="filled !== true"
              v-else/>
  </div>
  <div v-else-if="definition.type === 'autocomplete'">
    <v-autocomplete v-if="searchColumnItems != null"
                    hide-details
                    hide-no-data
                    cache-items
                    :filled="filled"
                    :solo="filled !== true"
                    :value="value"
                    @change="inputValue"
                    :search-input.sync="search"
                    dense
                    :hint="definition.hint"
                    clearable
                    :items="searchColumnItems"
                    :prefix="definition.name + ' ' + (definition.prefix || '')"
                    placeholder="Selecione"
                    item-text="valor"
                    item-value="key"
    />
    <v-select disabled loading
              :filled="filled"
              dense
              hide-details
              :hint="definition.hint"
              :prefix="definition.name + ' ' + (definition.prefix || '')"
              placeholder="Selecione"
              :items="[]"
              :solo="filled !== true"
              v-else/>
  </div>

    <v-text-field placeholder="Digite"
                  v-else
                  :value="value"
                  @input="inputValue"
                  :persistent-hint="true"
                  dense
                  clearable
                  :filled="filled"
                  :solo="filled !== true"
                  :hint="definition.hint"
                  :hide-details="definition.hint == null"
                  :prefix="definition.name + ' ' + (definition.prefix || '')"
                  :type="definition.type"></v-text-field>


</template>

<script>
export default {
  name: "AppSearchField",
  props: ['value', 'definition', 'filled'],
  data() {
    return {
      search: ''
    }
  },
  asyncComputed: {
    async searchColumnItems() {
      const fn = this.definition?.optionsFactory;

      if (typeof fn === 'function') {
        return fn(this.search);
      }
      return [];
    }
  },
  methods: {
    inputValue(v) {
      this.$emit('input', v);
    }
  }
}
</script>

<style scoped lang="scss">

</style>